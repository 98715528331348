import React from 'react'
import Layout from '../../components/layout/layout'
import SEO from "../../components/seo"
import CCBreadcrumb from '../../components/layout/breadcrumb'

import Alert from 'react-bootstrap/Alert'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import CardDeck from 'react-bootstrap/CardDeck'

import bscsLogo from '../../images/logos/bscs_logo.svg'
import opbLogo from '../../images/logos/opb_logo.svg'


const ProjectPartners = (props) => (
  <Layout location={props.location.pathname}>
    <SEO title="Carbon Connections - Project Partners" />
    <CCBreadcrumb
      pathname={props.location.pathname}
      title={'Project Partners'}
      replace={props.replace}
    />
    <Container fluid className="mb-5">
      <Row className="d-flex">
        <Col>
          <Alert
            className="w-100"
            variant="warning"
          >
            BSCS is maintaining this educational resource for archival purposes. Some information may be out of date. We are no longer supporting the resource for classroom use.
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Row>
                <Col>
                  <Card>
                    <Card.Header className="cardHeader">
                      <h1 className="headerFontBlue">Project Partners</h1>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <CardDeck>
                        <Card className="">
                          <Card.Img className="cardBodyBackground" src={ bscsLogo } />
                          <Card.Body className="cardBodyBackground">
                            <Card.Text>
                              The Biological Sciences Curriculum Study has more than 50 years of experience in leadership in science education, including curriculum development, professional development, and research and in science education. The mission of BSCS is to transform science teaching and learning through research and development that strengthens learning environments and inspires a global community of scientifically literate citizens.
                            </Card.Text>
                          </Card.Body>
                        </Card>

                        <Card>
                          <Card.Img className="cardBodyBackground" src={ opbLogo } />
                          <Card.Body className="cardBodyBackground">
                            <Card.Text>
                              Oregon Public Broadcasting is a leading public broadcasting station, serving 1.5 million residents of Oregon and southwest Washington. OPB produces award-winning news and television programming, and multimedia materials for students, teachers, and learners in formal and informal settings locally and nationally. Working with national academic experts, educators, and evaluators, OPB produces an array of multimedia curriculum and courses in the humanities and sciences for middle and high school students, as well as teacher professional development.
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </CardDeck>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default ProjectPartners
